import formatDate from '@/components/js/filter/dateFormat'

export default function dateformate(val) {
  if (val) {
    try {
      const date = val.toDate()
      return formatDate(date, 'yyyy年MM月dd日HH時mm分')
    } catch (error) {
      window.console.log(error)
      return ''
    }
  }
  return ''
}
