<template>
  <b-row class="match-height">
    <b-col lg="12">
      <ConfirmBasicDashboad />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import ConfirmBasicDashboad from '@/components/student/dashboad/ConfirmBasicDashboad.vue'

export default {
  components: {
    BRow,
    BCol,
    ConfirmBasicDashboad,
  },
  data() {
    return {
    }
  },
  computed: {
  },
  mounted() {
  },
}
</script>

<style lang="scss">
</style>
