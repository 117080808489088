import firebase from 'firebase/app'
import store from '@/store'

export default class UpdateSingleData {
  constructor() {
    this.label = ''
  }

  async update(payload) {
    const { collection, data } = payload
    const dic = data
    const uid = store.getters['userBasic/uid']
    const mail = store.getters['userBasic/mail']
    try {
      dic.updateTime = new Date()
      dic.updateUid = uid
      dic.updateMail = mail

      await firebase.firestore().collection(collection).doc(dic.docID).set(dic, { merge: true })

      const response = {
        status: 'success',
      }
      return response
    } catch (error) {
      const response = {
        status: 'error',
        msg: error,
      }
      return response
    }
  }
}
